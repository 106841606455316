import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../shared/services/event/event.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  closeResult: string;
  eventname: any;
  eventdesc: any;
  DeleteName: any;
  delid: any;
  datetime: any;
  p = 0;
  Events: any;
  entid: any;

  constructor(private modalService: NgbModal, private eventservice: EventService) { }

  ngOnInit(): void {
    this.GetBlogs();
  }

  GetBlogs() {

    this.eventservice.GetBlogs().subscribe(
      (data: any) => {
        this.Events = data;
        console.log(data);

      });

  }
  // fileToUpload: File = null;
  fileToUpload1: File = null;

  // handleFileInput(files: FileList) {
  //   this.fileToUpload = files.item(0);
  // }
  handleFileInput1(files: FileList) {
    this.fileToUpload1 = files.item(0);
  }
  OnSubmitEditBlog(form: any) {

    var date = form.controls["datetime"].value;

    const formData: FormData = new FormData();
    formData.append('eventname', form.controls["eventname"].value);
    formData.append('eventdesc', form.controls["eventdesc"].value);
    formData.append('eventdate', date);
    formData.append('id', this.id);
    document.getElementById("addre").click();

    this.eventservice.EditBlog(formData).subscribe(
      (data: any) => {
        if (data == "0") {
          alert("Edited Succesfully");
          this.GetBlogs();
          this.getDismissReason(ModalDismissReasons.ESC);
        }

        else {
          alert("ERROR");
        }

      });


  }

  OnSubmitAddBlog(form: any) {

    const formData: FormData = new FormData();
    // formData.append('fileKey', this.fileToUpload, this.fileToUpload.name);
    formData.append('fileKey1', this.fileToUpload1, this.fileToUpload1.name);

    formData.append('eventname', form.controls["eventname"].value);
    formData.append('eventdesc', form.controls["eventdesc"].value);
    formData.append('eventdate', this.datetime);




    document.getElementById("addre").click();
    this.eventservice.AddBlog(formData).subscribe(
      (data: any) => {
        if (data == "0") {
          alert("Added Succesfully");
          this.GetBlogs();
          this.getDismissReason(ModalDismissReasons.ESC);
        }

        else {
          alert("ERROR");
        }

      });

  }

  DeleteWorkerRow() {
    console.log(this.delid)
    this.eventservice.DeleteBlog(this.delid).subscribe(
      (data: any) => {
        if (data == "1") {
          alert("Deleted Succesfully");
          this.GetBlogs();

        }

        else {
          alert("Please Try Again");
        }
      });
  }
  id;
  Modalpopupedit(data, edit) {
    this.entid = data.id;
    this.eventname = data.eventName;
    this.eventdesc = data.eventDescription;
    this.datetime = new Date(data.eventDate);
    this.id = data.id;
    console.log(this.datetime);
    this.modalService.open(edit).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // this.m = this.msg;
    });
  }

  Modalpopupdel(data, Sharecontent) {




    this.DeleteName = data.eventName;
    this.delid = data.id;

    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // this.m = this.msg;
    });
  }
  url;

  // @ViewChild('videoPlayer') videoplayer: ElementRef;
  // toggleVideo() {
  //   this.videoplayer.nativeElement.play();
  // }
  Modalpopupplay(data, Sharecontent) {




    this.url = data.eventDocuments;

    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // this.m = this.msg;
    });
  }
  Modalpopup(Sharecontent) {
    this.eventname = "";
    this.eventdesc = "";

    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // this.m = this.msg;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



}