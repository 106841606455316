<app-header></app-header>
<div class="col-md-12 row">
    &nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>


<h2 class="" style="text-align: center;">Blog</h2>
<div class="panel panel-primary">
    <div class="col-md-12 row">
        <div class="col-md-1"></div>
        <div class="col-md-2">
            <button type="button" class="btn btn-outline-dark btn-primary" (click)="Modalpopup(AddBlog)" style="color: black; color: white;padding: 5px;margin-left: 10px;">        
                            Add Blog
                     </button>
        </div>
    </div>
    <div class="col-md-12 row">&nbsp;</div>
    <div class="panel-body">
        <div class="col-md-12 row">
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-10">
                <div class="table-responsive">
                    <table class="table table-striped" style="border-collapse: collapse;table-layout: fixed;">
                        <tr class="thead-dark" style="font-size: 17px;">
                            <!-- <th></th> -->
                            <th>SI NO</th>
                            <th>Blog </th>
                            <th>Blog Description</th>
                            <th>Blog Date</th>
                            <!-- <th>Blog Video</th> -->
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                        <tr *ngFor="let event of Events;
                    let i = index">
                            <td>{{ (i + 1) }}</td>
                            <td>{{event.eventName}}</td>
                            <td style="word-wrap: break-word;max-width: 30%;">{{event.eventDescription}}</td>
                            <td>{{event.eventDate}}</td>
                            <!-- <td><button type="button" title="Play Video" class="btn btn-default btn-xs" style="background-color:blue;border:none;color: white" (click)="Modalpopupplay(event,play)">
                                Play
                            </button></td> -->
                            <td>
                                <button type="button" title="Edit Task" class="btn btn-default btn-xs" style="background-color:blue;border:none;color: white" (click)="Modalpopupedit(event,EditBlog)">
                                Edit
                            </button>
                            </td>
                            <td>
                                <button type="button" title="Delete Task" class="btn btn-default btn-xs" style="background-color:red;border:none;color: white;" (click)="Modalpopupdel(event,DeleteBlog)">
                               Delete
                            </button>
                            </td>

                        </tr>
                    </table>
                    <br/>

                </div>
            </div>
        </div>
    </div>
</div>














<ng-template #AddBlog let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
            <b>Add Blog</b>
        </h4>

        <button type="button" id="addre" class="close" aria-label="Close" (click)="d('Cross click')">
<span aria-hidden="true">&times;</span>
</button>
    </div>
    <form name="register" action="" #Eventform="ngForm" (ngSubmit)="OnSubmitAddBlog(Eventform)">

        <div class="modal-body" style="font-size: 16px;">
            <div>
                <br>
                <div>

                    <input type="text" class="form-control" [(ngModel)]="eventname" name="eventname" placeholder="Blog Name" ngModel/>
                    <br>
                    <textarea type="text" class="form-control" [(ngModel)]="eventdesc" name="eventdesc" placeholder="Blog Description" rows="4" cols="50" ngModel></textarea>

                    <br>

                    <input type="date" class="form-control" id="inputName" name="datetime" [(ngModel)]="datetime" ngModel/>


                    <br>
                    <!-- <div class="form-group">
                        <label for="file">Add Video file for Blog   &nbsp;</label>
                        <input type="file" id="file" accept="video/*" (change)="handleFileInput($event.target.files)">
                    </div>
                    <br> -->
                    <div class="form-group">
                        <label for="file">Add Image for Blog Poster</label>
                        <input type="file" id="file" accept="image/x-png,image/gif,image/jpeg" (change)="handleFileInput1($event.target.files)">
                    </div>
                </div>


            </div>
        </div>
        <br />
        <div class="modal-footer">
            <div>


                <button type="submit" class="btn btn-rounded btn-orange btn-icon-right">Add<i class="fa fa-long-arrow-right"></i></button>

            </div>
        </div>
    </form>

    <br />
</ng-template>



<ng-template #EditBlog let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
            <b>Edit Event</b>
        </h4>

        <button type="button" id="addre" class="close" aria-label="Close" (click)="d('Cross click')">
<span aria-hidden="true">&times;</span>
</button>
    </div>
    <form name="event" action="" #eventform="ngForm" (ngSubmit)="OnSubmitEditBlog(eventform)">

        <div class="modal-body" style="font-size: 16px;">
            <div>
                <br>
                <div>

                    <input type="text" class="form-control" [(ngModel)]="eventname" name="eventname" placeholder="Event Name" ngModel/>
                    <br>
                    <textarea type="text" class="form-control" [(ngModel)]="eventdesc" name="eventdesc" placeholder="Event Description" rows="4" cols="50" ngModel></textarea>

                    <br>

                    <input type="date" class="form-control" id="inputName" name="datetime" (ngModelChange)="datetime = $event" [ngModel]="datetime | date:'yyyy-MM-dd'" ngModel/>
                    <br>


                </div>


            </div>
        </div>
        <br />
        <div class="modal-footer">
            <div>


                <button type="submit" class="btn btn-rounded btn-orange btn-icon-right">Edit<i class="fa fa-long-arrow-right"></i></button>

            </div>
        </div>
    </form>

    <br />
</ng-template>


<ng-template #DeleteBlog let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete Event</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
    </div>
    <div class="modal-body">
        <h5>Are your sure? Do you want delete &nbsp;<b>{{ DeleteName }}!!</b></h5>
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="DeleteWorkerRow()" (click)="d('Cross click')" style="color: black; cursor: pointer;">
                Ok
            </button>
        <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')" style="color: black; cursor: pointer;">
                Cancel
            </button>
    </div>
    <br />
</ng-template>


<!-- <ng-template #play let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Please wait until video loads</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
    </div>
    <div class="modal-body">
        <video style="width: 100%;height: 100%;" src="{{url}}" poster="{{url}}#t=2" controls (click)="toggleVideo()" preload #videoPlayer>
            <source src="{{url}}"   type="video/mp4" />
            Browser not supported
        </video>
    </div>
    <br />
    <div class="modal-footer">

        <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')" style="color: black; cursor: pointer;">
                Close
            </button>
    </div>
    <br />
</ng-template> -->
<app-footer></app-footer>