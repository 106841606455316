import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'SA-PRMIA';


  ngOnInit() {
    if (environment.production) {
      if (location.protocol === 'http:') {
        if(window.location.href.includes('www.'))
        window.location.href = location.href.replace('http://www.', 'http://');
        else
        window.location.href = location.href.replace('http', 'https');
      }
    }
  }
}