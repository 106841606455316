import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../shared/services/event/event.service';

@Component({
  selector: 'app-count',
  templateUrl: './count.component.html',
  styleUrls: ['./count.component.css']
})
export class CountComponent implements OnInit {
  Lists:any;
  compcount: any;
  constructor(private modalService:NgbModal,private eventservice:EventService) { }

  ngOnInit(): void {
    this.GetLists();
    this.getalllist();

  }
  GetLists()
  {
   
    this.eventservice.GetLists().subscribe(
      (data: any) => {
         this.Lists = data;
         console.log(data);
         this.Lists.forEach(element => {
          element.eventPoster= (element.eventPoster==null)?"https://i.stack.imgur.com/s96ST.jpg":element.eventPoster;
        });
      });
  
  }
  getalllist() {
    this.eventservice.GetLists().subscribe((data: any) => {
      this.compcount = data.length;
    });
  }
  

}
