import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { UserService } from '../shared/services/userService/user.service';
import { HttpClient } from '@angular/common/http';
import { SAMember } from '../shared/viewmodels/member';
import { Router } from '@angular/router';
@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css']
})
export class MemberComponent implements OnInit {
  prefixes;
  suffixes;
  selprefix;
  selsuffix;
  uid;
  constructor(private userService: UserService, private http: HttpClient,private router:Router) {
    this.uid = localStorage.userId;
    this.email = localStorage.MailId;
  }

  ngOnInit(): void {
    this.userService.getprefix().subscribe((data: any) => this.prefixes = data);
    this.userService.getsuffix().subscribe((data: any) => this.suffixes = data);

  }
  prefix(event) {
    this.selprefix = event.target.value;

  }
  pincode;
  city;
  state;
  country;
  fname;
  mname;
  lname;
  email;
  address1;
  address2;
  address3;
  mobile;
  phone;
  organisation;

  getalldata() {
    var pin = this.pincode;
    this.http.get("https://api.postalpincode.in/pincode/" + pin).subscribe((data: any) => {
      console.log(data);
      if (data[0].Message == "No records found") {
        alert("Invalid Pincode");
      }
      else {
        this.state = data[0].PostOffice[0].State;
        this.country = data[0].PostOffice[0].Country;
        this.city = data[0].PostOffice[0].District;
      }
    })
  }
  suffix(event) {
    this.selsuffix = event.target.value;
  }
  submit() {
    const data: SAMember = {
      Address: this.address1 + "," + this.address2 + "," + this.address3,
      Country: this.country,
      FirstName: this.fname,
      Id: null,
      LastName: this.lname,
      MiddleName: this.mname,
      MobilePhone: this.mobile,
      Organization: this.organisation,
      Phone: this.phone,
      Pincode: this.pincode,
      Prefix: this.selprefix,
      State: this.state,
      Suffix: this.state,
      UserId: this.uid
    }
    this.userService.postmember(data).subscribe((data: any) => (data == 1) ? this.saved() : alert("Something went wrong please try again!"))
  }
  saved(){
    alert("Data Saved !")
    this.router.navigate(['/'])
  }
}
