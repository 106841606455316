<section id="contact-us" class="contact-us section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-title">
                    <h2>Member Registration Form</h2>
                    <!-- <p>We value our members and trainee. </p> -->
                </div>
            </div>
        </div>
        <div class="contact-head">
            <div class="row">
                <div class="col-lg-5 col-12">
                    <div class="single-head overlay">
                        <div class="single-info">
                            <Blockquote>
                                <h3 class="title" style="color: black;font-weight: 600;font-size: 24px;"><q>Register and get benefits</q></h3>
                            </Blockquote>

                            <i class="lni lni-book"></i>
                            <h4 class="title" style="font-weight: 600;font-size: 20px;"><u>Learning &amp; Development &amp; Microfinance</u>:</h4>
                            <ul>
                                <li><a href="https://www.prmia.org/Public/Public/Learning/Member_Webinars.aspx">1. 24 Thought Leadership webinars per year</a></li>
                                <li><a href="https://www.riminitiative.org/graduation-model/">2. RIM Graduation Model Basic Framework</a></li>

                            </ul>
                        </div>
                        <div class="single-info">
                            <i class="lni lni-empty-file"></i>
                            <h4 class="title" style="font-weight: 600;font-size: 20px;"><u>Exams</u>:</h4>
                            <ul>
                                <li><a href="https://www.prmia.org/Public/Public/Learning/Self-Study_Learning.aspx">1. Discount on optional PRM and certificate online exam prep courses</a></li>
                                <li><a href="https://www.prmia.org/Public/Public/Certificate/Certificates.aspx">2. Discount on optional PRM and certificate online exam prep courses</a></li>

                            </ul>
                        </div>
                        <div class="single-info">
                            <i class="lni lni-network"></i>
                            <h4 class="title" style="font-weight: 600;font-size: 20px;"><u>Networking/Events</u>:</h4>
                            <ul>
                                <li><a href="https://www.prmia.org/Public/Public/Network/Events.aspx">1. Member pricing on conferences</a></li>
                                <li><a href="https://www.prmia.org/Public/Public/Network/PRMIA_Chapters.aspx">2. Chapter event member pricing</a></li>

                            </ul>
                        </div>
                        <div class="single-info">
                            <i class="lni lni-target-customer"></i>
                            <h4 class="title" style="font-weight: 600;font-size: 20px;"><u>Resources &amp; Career Services</u>:</h4>
                            <ul>
                                <li><a href="https://www.prmia.org/Public/Public/Resources/Publications.aspx">1. Member pricing on select PRMIA publications</a></li>
                                <li><a href="https://jobs.prmia.org/">2. Resume posting</a></li>

                            </ul>
                        </div>

                    </div>
                </div>
                <div class="col-lg-7 col-12">
                    <div class="form-main">
                        <div class="title">
                            <h4></h4>
                            <h3></h3>

                        </div>
                        <form class="form" ngNativeValidate>
                            <div class="row">
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Prefix<span></span></label>
                                        <br>
                                        <select (change)="prefix($event)" name="prefix" id="" style="width: 100%;height: 48px;border-radius: 5px;">
                                            <option value=""  >(None)</option>
                                            <option value="{{p.id}}" *ngFor="let p of prefixes">{{p.prefix}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">

                                        <label>First Name<span>*</span></label>
                                        <input name="fname" type="text" placeholder="" [(ngModel)]="fname" required>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Middle Name<span></span></label>
                                        <input name="mname" type="text" placeholder="" [(ngModel)]="mname">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Last Name<span>*</span></label>
                                        <input name="lname" type="text" placeholder="" [(ngModel)]="lname" required>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Suffix<span></span></label>
                                        <br>
                                        <select (change)="suffix($event)" name="suffix" id="" style="width: 100%;height: 48px;border-radius: 5px;">
                                            <option value=""  >(None)</option>
                                            <option value="{{p.id}}" *ngFor="let p of suffixes">{{p.suffix}}</option>

                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Organisation<span>*</span></label>
                                        <input name="organisation" type="text" placeholder="" [(ngModel)]="organisation" required>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Your Email<span>*</span></label>
                                        <input name="email" type="email" disabled placeholder="" [(ngModel)]="email" required>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Your Phone<span></span></label>
                                        <input name="phone" type="number" placeholder="" [(ngModel)]="phone" required>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Mobile<span></span></label>
                                        <input name="mobile" type="number" placeholder="" [(ngModel)]="mobile" required>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-12">
                                    <div class="form-group">
                                        <label>Address<span></span></label>
                                        <div>
                                            <input name="address1" style="margin-bottom: 10px;" type="text" placeholder="" [(ngModel)]="address1">

                                            <input name="address2" style="margin-bottom: 10px;" type="text" placeholder="" [(ngModel)]="address2">

                                            <input name="address3" type="text" placeholder="" [(ngModel)]="address3">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Pincode<span>*</span></label>
                                        <input name="pincode" type="number" placeholder="" (blur)="getalldata()" [(ngModel)]="pincode" required>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>City<span></span></label>
                                        <input name="city" disabled type="text" placeholder="" [(ngModel)]="city" required>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>State<span></span></label>
                                        <input name="state" disabled type="text" placeholder="" [(ngModel)]="state" required>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Country<span></span></label>
                                        <input name="country" disabled type="text" placeholder="" [(ngModel)]="country" required>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group button">
                                        <button type="submit" (click)="submit()" class="btn ">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>