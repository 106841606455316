<app-header></app-header>
<div class="col-md-12 row">
    &nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>


<h2 class="" style="text-align: center;">Webinar</h2>
<div class="panel panel-primary">
    <div class="col-md-12 row">
        <div class="col-md-1"></div>
        <div class="col-md-2">
            <button type="button" class="btn btn-outline-dark btn-primary" (click)="Modalpopup(AddEvent)" style="color: black; color: white;padding: 5px;margin-left: 10px;">        
                            Add Event
                     </button>
        </div>
    </div>
    <div class="col-md-12 row">&nbsp;</div>
    <div class="panel-body">
        <div class="col-md-12 row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <div class="table-responsive">
                    <table class="table table-striped" style="border-collapse: collapse;table-layout: fixed;">
                        <tr class="thead-dark" style="font-size: 17px;">
                            <!-- <th></th> -->
                            <th>SI NO</th>
                            <th>Event </th>
                            <th>Event Description</th>
                            <th>Event Date</th>
                            <th>Event Time</th>
                            <th>Event Meeting Link</th>
                            <th>Event Brouchure Link</th>
                            <th>Event Flyer Link</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                        <tr *ngFor="let event of Events;
                    let i = index">
                            <td>{{ (i + 1) }}</td>
                            <td>{{event.eventName}}</td>
                            <td style="word-wrap: break-word;max-width: 30%;">{{event.eventDescription}}</td>
                            <td>{{event.eventDate}}</td>
                            <td>{{event.eventTime}}</td>
                            <td style="word-wrap: break-word;max-width: 30%;">{{event.eventMeetingLink}}</td>
                            <td style="word-wrap: break-word;max-width: 30%;">{{event.eventBrouchure}}</td>
                            <td style="word-wrap: break-word;max-width: 30%;">{{event.eventFlyer}}</td>

                            <td>
                                <button type="button" title="Edit Task" class="btn btn-default btn-xs" style="background-color:blue;border:none;color: white" (click)="Modalpopupedit(event,EditEvent)">
                                Edit
                            </button>
                            </td>
                            <td>
                                <button type="button" title="Delete Task" class="btn btn-default btn-xs" style="background-color:red;border:none;color: white;" (click)="Modalpopupdel(event,DeleteEvent)">
                               Delete
                            </button>
                            </td>

                        </tr>
                    </table>
                    <br/>

                </div>
            </div>
        </div>
    </div>
</div>














<ng-template #AddEvent let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
            <b>Add Event</b>
        </h4>

        <button type="button" id="addre" class="close" aria-label="Close" (click)="d('Cross click')">
<span aria-hidden="true">&times;</span>
</button>
    </div>
    <form name="register" action="" #Eventform="ngForm" (ngSubmit)="OnSubmitAddEvent(Eventform)">

        <div class="modal-body" style="font-size: 16px;">
            <div>
                <br>
                <div>

                    <input type="text" class="form-control" [(ngModel)]="eventname" name="eventname" placeholder="Event Name" ngModel/>
                    <br>
                    <textarea type="text" class="form-control" [(ngModel)]="eventdesc" name="eventdesc" placeholder="Event Description" rows="4" cols="50" ngModel></textarea>

                    <br>

                    <input type="date" class="form-control" id="inputName" name="datetime" [(ngModel)]="datetime" ngModel/>


                    <br>
                    <input type="text" class="form-control" id="inputName" name="eventtime" placeholder="Event Time" [(ngModel)]="eventtime" ngModel/>
                    <br>
                    <input type="text" class="form-control" id="inputName" name="eventBrouchure" placeholder="Event Brouchure Link" [(ngModel)]="eventbrouchure" ngModel/>
                    <br>
                    <input type="text" class="form-control" id="inputName" name="eventFlyer" placeholder="Event Flyer Link" [(ngModel)]="eventflyer" ngModel/>
                    <br>
                    <input type="text" class="form-control" id="inputName" name="eventMeetingLink" placeholder="Event Meeting Link" [(ngModel)]="eventmeetingLink" ngModel/>
                    <br>

                </div>


            </div>
        </div>
        <br />
        <div class="modal-footer">
            <div>


                <button type="submit" class="btn btn-rounded btn-orange btn-icon-right">Add<i class="fa fa-long-arrow-right"></i></button>

            </div>
        </div>
    </form>

    <br />
</ng-template>



<ng-template #EditEvent let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
            <b>Edit Event</b>
        </h4>

        <button type="button" id="addre" class="close" aria-label="Close" (click)="d('Cross click')">
<span aria-hidden="true">&times;</span>
</button>
    </div>
    <form name="event" action="" #eventform="ngForm" (ngSubmit)="OnSubmitEditEvent(eventform)">

        <div class="modal-body" style="font-size: 16px;">
            <div>
                <br>
                <div>

                    <input type="text" class="form-control" [(ngModel)]="eventname" name="eventname" placeholder="Event Name" ngModel/>
                    <br>
                    <textarea type="text" class="form-control" [(ngModel)]="eventdesc" name="eventdesc" placeholder="Event Description" rows="4" cols="50" ngModel></textarea>

                    <br>

                    <input type="date" class="form-control" id="inputName" name="datetime" (ngModelChange)="datetime = $event" [ngModel]="datetime | date:'dd-MM-yyyy'" ngModel/>
                    <br>
                    <input type="text" class="form-control" id="inputName" name="eventtime" placeholder="Event Time" [(ngModel)]="eventtime" ngModel/>
                    <br>
                    <input type="text" class="form-control" id="inputName" name="eventBrouchure" placeholder="Event Brouchure Link" [(ngModel)]="eventbrouchure" ngModel/>
                    <br>
                    <input type="text" class="form-control" id="inputName" name="eventFlyer" placeholder="Event Flyer Link" [(ngModel)]="eventflyer" ngModel/>
                    <br>
                    <input type="text" class="form-control" id="inputName" name="eventMeetingLink" placeholder="Event Meeting Link" [(ngModel)]="eventmeetingLink" ngModel/>
                    <br>


                </div>


            </div>
        </div>
        <br />
        <div class="modal-footer">
            <div>


                <button type="submit" class="btn btn-rounded btn-orange btn-icon-right">Edit<i class="fa fa-long-arrow-right"></i></button>

            </div>
        </div>
    </form>

    <br />
</ng-template>


<ng-template #DeleteEvent let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete Event</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
    </div>
    <div class="modal-body">
        <h5>Are your sure? Do you want delete &nbsp;<b>{{ DeleteName }}!!</b></h5>
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="DeleteWorkerRow()" (click)="d('Cross click')" style="color: black; cursor: pointer;">
                Ok
            </button>
        <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')" style="color: black; cursor: pointer;">
                Cancel
            </button>
    </div>
    <br />
</ng-template>



<app-footer></app-footer>