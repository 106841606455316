import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Signup } from '../shared/viewmodels/signup';
import { UserService } from '../shared/services/userService/user.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
declare var _podioWebForm:any;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(private userService:UserService,private router:Router,private spinner: NgxSpinnerService) {

    if(window.innerWidth>500)
    {
      setTimeout(() => {
        document.getElementById("login").style.opacity="1";
    
      document.getElementById("sign-up").style.opacity="0";
      }, 500);
      
    }
    else
    {
      setTimeout(() => {
        
        document.getElementById("login").style.display="block";
    
        document.getElementById("sign-up").style.display="none";
        document.getElementById("slider").style.display="none";
      }, 500);
     
    }
    


   }

  ngOnInit(): void {


  }
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  phonev = new RegExp(/^[6-9]\d{9}$/);
  serchfind;
  email;
  pwd;
  name;
  phonetwo;
  phone;
  onlyphone;
  alldetails;
  otp;
  otpp;
  institution;
  branch;
  usn;
  outputbatch;
  expired;
  signup(){
    if(this.otp||this.otpp){
      this.otpsubmit();
    }
    else{
    if(!this.onlyphone){
    this.serchfind = this.regexp.test(this.email);
    if(!this.serchfind){
      //  this.spinner.hide();  
        alert('Please Enter valid Email');
        return;
      }
      const inputRequest: Signup = {
        Email: this.email,
        FirstName: this.name,
        Password: this.pwd,
        ConfirmPassword: this.pwd,
        PhoneNumber: null,
        UserName: 'jobseeker',
        LastName:null,
        Id:null,
        PlanId:2,
        Question:null,
        Branch:null,
        Institution:null,
        USN:null,
        BranchName:null,
        SubRoleForApplicant:999
      }
      this.alldetails = inputRequest;
    

    }
    else{
      this.phonetwo = this.phonev.test(this.phone);
       if (!this.phonetwo) { 
        alert('Please Enter valid Mobile Number');
       // this.spinner.hide();
        return;
      }
      const inputRequest: Signup = {
        Email:null,
        FirstName: this.name,
        Password: this.pwd,
        ConfirmPassword: this.pwd,
        PhoneNumber: this.phone,
        UserName: 'jobseeker',
        LastName:null,
        Id:null,
        PlanId:2,
        Question:null,
        Branch:null,
        Institution:null,
        USN:null,
        BranchName:null,
        SubRoleForApplicant:999
      }
      this.alldetails = inputRequest;
    }
    this.spinner.show();
    this.userService.sendotp(this.alldetails).subscribe(
      (data: any) => {
        this.spinner.hide();

        console.log(data)
        if (data == "1") {
          if(this.onlyphone){
          alert("Otp has been sent to your Registered Phone Number..!!! Please Enter the otp to complete registration process");
          this.otpp = true;
  
        }
          else{
            alert("Otp has been sent to your Registered Email..!!! Please Enter the otp to complete registration process");
            this.otp = true;

          }
        }
        else if(data == "0"){
          if(this.onlyphone){
            alert("Phone Number already exists");
          }
            else{
              alert("Email Id already exists");
  
            }
        }
        else {
          alert(data);
        }

      });
      
    }
  }
  otpsubmit(){
    this.spinner.show();

    if(!this.onlyphone){
      if (this.otp == null || this.otp == "" ) {
        alert("Please Enter Your Otp");
      }
      else if(this.otp.length <= 5 || this.otp.length > 10 ){
        alert("Please Enter valid Otp");
      }
      else{
        const inputRequest: Signup = {
          Id: 0,
          Email: this.alldetails.Email,
         
          FirstName: this.alldetails.FirstName,
          LastName: this.otp,
          UserName: this.alldetails.UserName,
          PhoneNumber: this.alldetails.PhoneNumber,
          Password: this.alldetails.Password,
          ConfirmPassword: this.alldetails.ConfirmPassword,
          Question :this.institution+","+this.branch+","+this.usn ,
          PlanId: null,
          Branch:this.branch,
          Institution:this.institution,
          USN:this.usn,
          BranchName:this.outputbatch,
          SubRoleForApplicant:999

          
        }
  
        this.userService.register(inputRequest).subscribe(
          (data: any) => {
            this.spinner.hide();

            if (data != "1" && data != "2") {
              alert("Registered Succesfully");
              localStorage.removeItem("regtype");
              localStorage.setItem("CusId", data.accountNo);
              localStorage.setItem("uid", data.accountNo)
            //login

            localStorage.isLoggedIn = "true";


            localStorage.setItem('loadingpage',"true");
            // this.loading = true;
            
             
           
             this.userService.userAuthentication(this.alldetails.Email,this.alldetails.Password).subscribe((data : any)=>
             {
             var firstName = data.userName;
               var movies = [ data.access_token, this.email, data.roleName, data.refresh_token,true,data.userUid, 
                              firstName[0],data.username, data.nameofRole, data.departmentname,data.userId,data.uid,data.area,data.IsMember ];
           console.log(data)
              localStorage.setItem('IsLoggedIn',"true");
              localStorage.setItem('userToken',data.access_token);
              localStorage.setItem('authorizationData',data);
              localStorage.setItem('Token_type',data.token_type);
              localStorage.setItem("UserName",data.fullName);
              //localStorage.setItem("this.password",password);
              localStorage.setItem("role",data.roleName);
              localStorage.setItem("userId",data.userId);
              localStorage.setItem("uid",data.uid);
              localStorage.setItem("MailId",data.mailId);
              //localStorage.setItem("area",data.area);
              //localStorage.setItem("isMember",data.IsMember);
              //localStorage.setItem("gst",data.gst);
              localStorage.setItem('authdata',JSON.stringify(movies) );
           localStorage.storage = data.storage;
           
               // send message to subscribers via observable subject
              
             
               //var landingUrl = "http://" + window.location.host + "/home";
               // var landingUrl = "http://" + window.location.host + "/newhome";
               // window.location.href = landingUrl;
           
           
              
           
               this.getpage();


            //login end
            
             
           
            });
            }
            else if (data == "1") {

              alert("Incorrect OTP");
            }
            else if (data == "2") {
              alert("OTP Expired");

              this.expired = true;
            }
            else {
              alert("Something Went Wrong. Try again.!!");
              window.location.reload();
            }
          });
  
      }
    }
    else{
      if (this.otpp == null || this.otpp == "" ) {
        alert("Please Enter Your Otp");
      }
      else if(this.otpp.length <= 5 || this.otpp.length > 10 ){
        alert("Please Enter valid Otp");
      }
      else{
        const inputRequest: Signup = {
          Id: 0,
          Email: this.alldetails.Email,
         
          FirstName: this.alldetails.FirstName,
          LastName: this.otpp,
          UserName: this.alldetails.UserName,
          PhoneNumber: this.alldetails.PhoneNumber,
          Password: this.alldetails.Password,
          ConfirmPassword: this.alldetails.ConfirmPassword,
          Question :this.institution+","+this.branch+","+this.usn ,
          PlanId: null,
          Branch:this.branch,
          Institution:this.institution,
          USN:this.usn,
          BranchName:this.outputbatch,
          SubRoleForApplicant:999
        }
  this.spinner.show();
        this.userService.register(inputRequest).subscribe(
          (data: any) => {
            this.spinner.hide();

            if (data != "1" && data != "2") {
              alert("Registered Succesfully");
              this.router.navigate(['/newlogin']);
            }
            else if (data == "1") {
              alert("Incorrect OTP");
            }
            else if (data == "2") {
              alert("OTP Expired");
            }
            else {
              alert("Something Went Wrong. Try again.!!");
              
            }
          });
  
      }
    }
  }
  changetophone(){
    if(this.onlyphone){
      this.onlyphone = false;
    }
    else{
        this.onlyphone = true;
    }
  }
  removeClass(className){
    $('#auth').removeClass(className);

if(window.innerWidth>500)
{
  document.getElementById("login").style.opacity="1";

  document.getElementById(className).style.opacity="0";
}
else
{
  document.getElementById("login").style.display="block";

  document.getElementById(className).style.display="none";
}
   
  //  document.getElementById("slider").style.backgroundImage = "url('assets/img/signin.svg')";

  }
  addClass(className){
    $('#auth').addClass(className);
  //  console.log(className);
    if(window.innerWidth<500)
{
    document.getElementById(className).style.display="block";
    document.getElementById("login").style.display="none";

}
else
{
  document.getElementById("login").style.opacity="0";

  document.getElementById(className).style.opacity="1";
}
   // document.getElementById("slider").style.backgroundImage = "url('assets/img/signin.svg')";

  }
login(){
  this.userService.userAuthentication(this.email,this.pwd).subscribe((data : any)=>
  {

  var firstName = data.userName;
    var movies = [ data.access_token, this.email, data.roleName, data.refresh_token,true,data.userUid, 
                   firstName[0],data.username, data.nameofRole, data.departmentname,data.userId,data.uid,data.area,data.IsMember ];
console.log(data)
   localStorage.setItem('IsLoggedIn',"true");
   localStorage.setItem('userToken',data.access_token);
   localStorage.setItem('authorizationData',data);
   localStorage.setItem('Token_type',data.token_type);
   localStorage.setItem("UserName",data.fullName);
   //localStorage.setItem("this.password",password);
   localStorage.setItem("role",data.roleName);
   localStorage.setItem("userId",data.userId);
   localStorage.setItem("uid",data.uid);
   localStorage.setItem("MailId",data.mailId);
   //localStorage.setItem("area",data.area);
   //localStorage.setItem("isMember",data.IsMember);
   //localStorage.setItem("gst",data.gst);
   localStorage.setItem('authdata',JSON.stringify(movies) );
localStorage.storage = data.storage;

    // send message to subscribers via observable subject
   
    
    localStorage.isLoggedIn = "true";
    if(data.roleName==1){
      localStorage.admin="true";
    }
      this.getpage();
 },(err : HttpErrorResponse)=>{
  alert("Invalid credentials");

 }
 );
}
getpage(){
  var id = +localStorage.userId;
  this.userService.getpage(id).subscribe((data:any)=>{
    if(data==1){
      window.location.href="https://prmia-riskhub.srichid.academy/"
      //window.location.href="http://localhost:4200/"
    }
    else if(data==2){
      this.router.navigate(['/member']);
    }
    else{
      alert("Please try again");
    }
  })
}
gohome(){
 window.location.href="https://prmia-riskhub.srichid.academy/"
// window.location.href="http://localhost:4200/"
}
}
