<section id="footer" class="section footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-12">
                <div class="footer-logo">
                    <a href="index.html"><img src="assets/img/srichidlogo.png" alt="Srichid Logo"></a>
                </div>
                <div class="contact-info">
                    <p>
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
                <div class="single-footer f-link">
                    <h5>Quick menu</h5>
                    <ul>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#about-us">About Us</a></li>
                        <!-- <li><a href="#pricing">Pricing</a></li> -->
                        <!-- <li><a href="#services">Services</a></li> -->
                        <li><a href="https://srichid.com">Srichid</a></li>
                        <li><a href="https://prmia.org">PRMIA</a></li>

                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
                <div class="single-footer f-link">
                    <h5>Featured links</h5>
                    <ul>
                        <li class="nav-item"><a href="#faq">FAQ</a></li>
                        <li class="nav-item"><a href="register">Get Started</a></li>
                        <li class="nav-item"><a href="#features">Features</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
                <div class="single-footer f-contact">
                    <h5>Keep in touch</h5>
                    <div class="contact-info">
                        <p>
                            Srichid Academy
                        </p>
                    </div>
                    <p>
                        +91-80-26852070
                    </p>
                    <p>
                        <a href="mailto:prmia.riskhub.srichidacademy@gmail.com
                        ">prmia.riskhub.srichidacademy@gmail.com
                      </a>
                    </p>
                    <ul class="footer-social">
                        <li><a href=""><i class="lni lni-facebook-filled"></i></a></li>
                        <li><a href="https://twitter.com/SrichidAcademy"><i class="lni lni-twitter-original"></i></a></li>
                        <li><a href="https://www.linkedin.com/in/srichid-academy-60a0761a5/"><i class="lni lni-linkedin-original"></i></a></li>
                        <li><a href="https://www.instagram.com/_srichid_academy/"><i class="lni lni-instagram-filled"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p class="text-center copyright">2021 &copy; <a href="https://srichid.academy"> Srichid Academy </a></p>
            </div>
        </div>
    </div>
</section>