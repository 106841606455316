import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {map} from 'rxjs/operators';
import * as $ from 'jquery';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly globalurl="https://prmiaapi.srichid.academy";
 //private readonly globalurl="http://localhost:8541";
  constructor(private http:HttpClient) {
    
   }
   headers: HttpHeaders = new HttpHeaders();
   userAuthentication(userName, password) {
    var data = "username=" + userName + "&password=" + password + "&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded;', 'No-Auth': 'True' });
    return this.http.post(this.globalurl + '/token', data, { headers: reqHeader });
  }
   register(data){
    return this.http.post(this.globalurl+"/api/Account/SARegister",data);
   }
   sendotp(data){
     return this.http.post(this.globalurl+"/api/Account/SAsendotp",data);
   }
   getprefix(){
    return this.http.get(this.globalurl+"/api/Account/GetPrefix");
  }
  getsuffix(){
    return this.http.get(this.globalurl+"/api/Account/GetSuffix");
  }
  postmember(data){
    return this.http.post(this.globalurl+"/api/Account/PostMember",data);
  }
  getpage(id){
    return this.http.get(this.globalurl+"/api/Account/getpage/"+id);
  }
  sendmail(data){
    return this.http.post(this.globalurl+"/api/Account/Contact",data);
  }

}

