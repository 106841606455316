import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/services/userService/user.service';
import { HttpHeaders } from '@angular/common/http';
import {from, Observable} from 'rxjs';

declare var     _podioWebForm:any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
isLogged;
admin;
all;
uid;
  constructor(private service: UserService) {
    this.isLogged = localStorage.isLoggedIn;
    if(localStorage.userId){
    this.uid = +localStorage.userId;
    }
    this.admin=localStorage.admin;
    console.log(window.location.href);
   if(window.location.href=="https://prmia-riskhub.srichid.academy/" || window.location.href=="https://prmia-riskhub.srichid.academy/"||window.location.href=="https://prmia-riskhub.srichid.academy"||window.location.href=="https://prmia-riskhub.srichid.academy" ){
     // if(window.location.href=="http://localhost:4200/" || window.location.href=="http://localhost:4200/"||window.location.href=="http://localhost:4200"||window.location.href=="http://localhost:4200" ){
this.all=true;
    }
    else{
      this.all=false;
    }
   }

  ngOnInit(): void {
  }
  logout(){
    localStorage.clear();
    location.reload();
  }
  gohome(){
    window.location.href="https://prmia-riskhub.srichid.academy/";
    //window.location.href="http://localhost:4200/";
  }
  gojob(){
    
    // window.location.href="https://jobs-zone.com/newsignin/"+"prmialogin-"+this.uid;
    window.location.href="https://jobs-zone.com/";
  }


  gotomembership(){
    //  _podioWebForm.render("1809423")
    window.open("http://prmia.org/Public/PRM/Company_Pages/SRICHID_PRM_Enrollment.aspx","_blank");

  }
  gotoenroll(){
     _podioWebForm.render("1810747")
    
    // window.location.href="https://podio.com/webforms/24672883/1810747";

  }
}
