import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Signup } from '../shared/viewmodels/webinar';
import { EventService } from '../shared/services/event/event.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-registerwebinar',
  templateUrl: './registerwebinar.component.html',
  styleUrls: ['./registerwebinar.component.css']
})
export class RegisterwebinarComponent implements OnInit {
  otp = false;
  Name: any;

  Designation: any;
  response: any;
  Phone: any;
  id: any;
  notify: string;
  Organization: any;
  dismissalert: boolean;
  Email: any;
  constructor(
    private registrationservice: EventService,private spinner:NgxSpinnerService
  ) {}

  ngOnInit() {}
  OnSubmitJobPost(form: NgForm) {
    this.spinner.show();
    // var insid = localStorage.getItem("invinsuid");
    const inputRequest: Signup = {
      id: null,

      Name: form.controls['Name'].value,

      Phone: form.controls['Phone'].value,
      Email: form.controls['Email'].value,

      Organization: form.controls['Organization'].value,
      Designation: form.controls['Designation'].value,
    };
    this.registrationservice.save(inputRequest).subscribe((data: any) => {
      if (data != null) {
        alert('Registered Your Details Successfully');
        this.spinner.hide()
      } else {
        alert('You have already registered for the webinar');
        this.spinner.hide()

      }
      this.spinner.hide();
    });
  }
}
