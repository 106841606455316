<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<section id="auth">
    <form id="sign-up" ngNativeValidate>
        <h2>Sign Up</h2>
        <label>Name</label>
        <input type="text" placeholder="" id="name" name="name" [(ngModel)]="name" required />
        <label *ngIf="!onlyphone">Email</label>
        <input *ngIf="!onlyphone" type="email" placeholder="" id="email" name="email" [(ngModel)]="email" required />
        <label *ngIf="onlyphone">Phone</label>
        <input *ngIf="onlyphone" type="number" name="phone" id="phone" placeholder="" [(ngModel)]="phone" required>

        <div (click)="changetophone()" *ngIf="!onlyphone" style="color: #1e5ee5;cursor: pointer;">Sign up with phone number</div>
        <div (click)="changetophone()" *ngIf="onlyphone" style="color: #1e5ee5;cursor: pointer;">Sign up with Email</div>
        <br>
        <label>Password</label>
        <input type="password" placeholder="" id="pwd" name="pwd" [(ngModel)]="pwd" required/>
        <br>
        <div *ngIf="otp && !onlyphone">
            <input type="number" class="form-control" id="otp" name="otp" placeholder="Verify Email" [(ngModel)]="otp" />
        </div>
        <div *ngIf="otp && onlyphone">
            <input type="number" class="form-control" id="otp" name="otpp" placeholder="Verify Phone number" [(ngModel)]="otpp" />
        </div>
        <button type="submit" (click)="signup()">Sign Up</button>

        <div class="toggle">
            Already have an account?
            <span (click)="removeClass('sign-up')">Log in</span>
        </div>
        <div class="toggle">
            <span (click)="gohome()">Home</span>
        </div>


    </form>

    <form id="login" ngNativeValidate>
        <h2>Log In</h2>
        <label>Email or Phone</label>
        <input type="text" placeholder="" id="email" name="email" [(ngModel)]="email" />
        <label>Password</label>
        <input type="password" id="pwd" name="pwd" [(ngModel)]="pwd" placeholder=" " />
        <button type="submit" (click)="login()">Log In</button>
        <div class="toggle">
            Have you been here before?
            <span (click)="addClass('sign-up')">Sign up</span>
        </div>
        <div class="toggle">
            <span (click)="gohome()">Home</span>
        </div>
    </form>

    <div id="slider">
        <img src="assets/img/signin.svg" style="height: 100%;">
        <!-- <div id="login-text">
            <h1>Good to see you again</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </div>

        <div id="sign-up-text">
            <h1>Welcome to the thing</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </div> -->
    </div>
</section>