<br><br><br>
<div class="col-md-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
        <h5 class="title">
            <strong>Total Count: &nbsp; {{ compcount }}</strong>
        </h5>
        <br> <br>
        <div class="table-responsive">
            <table class="table table-striped" style="border-collapse: collapse;table-layout: fixed;">
                <tr class="thead-dark" style="font-size: 17px;">

                    <th>SI NO</th>
                    <th>Name </th>
                    <th>Email</th>


                </tr>
                <tr *ngFor="let list of Lists let i=index ">
                    <td>{{ (i + 1) }}</td>
                    <td>{{list.name}}</td>
                    <td>{{list.email}}</td>


                </tr>
            </table>
            <br/>

        </div>
    </div>
</div>