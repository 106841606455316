import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule,HttpClientJsonpModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { EventsComponent } from './events/events.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlleventsComponent } from './allevents/allevents.component';
import { MemberComponent } from './member/member.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgxSpinnerModule } from "ngx-spinner";
import { RegisterwebinarComponent } from './registerwebinar/registerwebinar.component';
import { WebminarEvent2Component } from './webminar-event2/webminar-event2.component';
import { BlogComponent } from './blog/blog.component';
import { AllblogsComponent } from './allblogs/allblogs.component';
import { WwbinarComponent } from './wwbinar/wwbinar.component';
import { AllwebinarsComponent } from './allwebinars/allwebinars.component';
import { CountComponent } from './count/count.component';
import { InviteComponent } from './invite/invite.component';
// import { TagInputModule } from 'ngx-chips';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RegisterComponent,
    HeaderComponent,
    FooterComponent,
    EventsComponent,
    AlleventsComponent,
    MemberComponent,
   // TagInputModule,
    RegisterwebinarComponent,
    WebminarEvent2Component,
    BlogComponent,
    AllblogsComponent,
    WwbinarComponent,
    AllwebinarsComponent,
    CountComponent,
    InviteComponent,
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgbModule,
    NgxSpinnerModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
