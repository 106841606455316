import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { EventsComponent } from './events/events.component';
import { AlleventsComponent } from './allevents/allevents.component';
import { MemberComponent } from './member/member.component';
import { RegisterwebinarComponent } from './registerwebinar/registerwebinar.component';
import { WebminarEvent2Component } from './webminar-event2/webminar-event2.component';
import { BlogComponent } from './blog/blog.component';
import { AllblogsComponent } from './allblogs/allblogs.component';
import { WwbinarComponent } from './wwbinar/wwbinar.component';
import { AllwebinarsComponent } from './allwebinars/allwebinars.component';
import { CountComponent } from './count/count.component';
import { InviteComponent } from './invite/invite.component';



const routes: Routes = [
  {
    path:'',
    component:HomeComponent
  },
  {
    path:'register',
    component:RegisterComponent
  },
  {
    path:'event',
    component:EventsComponent
  },
  {
    path:'invite',
    component:InviteComponent
  },
  {
    path:'events',
    component:AlleventsComponent
  },
  {
    path:'member',
    component:MemberComponent
  },
  {
    path:'webinar-registration',
    component:RegisterwebinarComponent
  },
  {
    path:'webinar-registration-prmia-chennai',
    component:WebminarEvent2Component
  },
  {
    path:'blog',
    component:BlogComponent
  },
  {
    path:'allblog',
    component:AllblogsComponent
  },
  {
    path:'webinar',
    component:WwbinarComponent
  },
  {
    path:'allwebinar',
    component:AllwebinarsComponent
  },
  {
    path:'count',
    component: CountComponent
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
