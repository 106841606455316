import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../shared/services/event/event.service';

@Component({
  selector: 'app-allblogs',
  templateUrl: './allblogs.component.html',
  styleUrls: ['./allblogs.component.css']
})
export class AllblogsComponent implements OnInit {

  
  Events:any;
  constructor(private modalService:NgbModal,private eventservice:EventService) { }

  ngOnInit(): void {
    this.GetBlogs();

  }
  GetBlogs()
  {
   
    this.eventservice.GetBlogs().subscribe(
      (data: any) => {
         this.Events = data;
         console.log(data);
         this.Events.forEach(element => {
          element.eventPoster= (element.eventPoster==null)?"https://i.stack.imgur.com/s96ST.jpg":element.eventPoster;
        });
      });
  
  }
  url;
  closeResult;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }
  Modalpopupplay(data,Sharecontent) {
     
  
  
  
    this.url=data;
  
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
  
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
     // this.m = this.msg;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
