import { Component, OnInit } from '@angular/core';
//import { TagInputModule } from 'ngx-chips';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css']
})
export class InviteComponent implements OnInit {
  frndslist: any;
  selectedFriend: any;

  friends: boolean;
  friend: any;
  email: any;
 
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  serchfind: boolean;
  errorMessages: string;
  constructor() { 
    // TagInputModule.withDefaults({
    //   tagInput: {
    //     placeholder: 'Add email address',
    //     secondaryPlaceholder: 'Add email address',
    //   },

    // });
  }

  ngOnInit(): void {
  }
  onItemRemoved(value: any) {
    //this.selecteduserlist.pop(value.display);
    for (var i = 0; i < this.frndslist.length; i++) {
      if (this.frndslist[i].Email == value.display) {
        var index = this.selectedFriend.indexOf(value);
        this.selectedFriend.splice(index, 1);
      }
    }
    this.errorMessages = "";
  }
  onItemAdded(value: any) {
    if (value.email == undefined) {
      this.email = value.value;
    }
    else {
      this.email = value.email;
    }
    this.serchfind = this.regexp.test(this.email);
    if (!this.serchfind) {
      this.errorMessages = "Please Enter valid Email";
    }
    if (value.uid != null) {
      this.selectedFriend.push(value);
    }
    else{
      alert("OOPS!! This person not in your Friends List.Let us add him/her Click Friend Request.");
      this.friends = true;   
        this.friend = this.email; 
      this.selectedFriend="";
 // this.savefrienddetails();
    }
    // else {
    //   this.selectedFriend.push(value.email);
    // }
  }
}
