import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/services/userService/user.service';
import { EmailVM } from '../shared/viewmodels/Email';
declare var     _podioWebForm:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  message;
  name;
  phone;
  email;
  subject;
  islogin=false;
  constructor(private sendEmailService:UserService) {
    this.islogin=localStorage.isLoggedIn;
    console.log(this.islogin)
   }

  ngOnInit(): void {
    
  }

  submit(){
    const email:EmailVM={
      Email:this.email,
      Message:this.message,
      Name:this.name,
      Phone:this.phone,
      Subject:this.subject
    }
    this.sendEmailService.sendmail(email).subscribe((data:any)=>{
      if(data){
        alert("Your message has reached us! We will contact you in some time.");
        this.email="";
        this.message="";
        this.name="";
        this.phone="";
        this.subject="";
      }
    })
  }
  gotomembership(){
    window.open("http://prmia.org/Public/PRM/Company_Pages/SRICHID_PRM_Enrollment.aspx","_blank");

   // window.location.href="https://podio.com/webforms/24663154/1809423";

 }
 gotoenroll(){
    _podioWebForm.render("1810747")
   
   // window.location.href="https://podio.com/webforms/24672883/1810747";

 }
}
