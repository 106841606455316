<header id="site-header" class="site-header">
    <div class="topbar">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                    <p>Welcome to Risk Hub</p>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="social-contact">
                        <ul>
                            <li><a href=""><i class="lni lni-facebook-filled"></i></a></li>
                            <li><a href="https://twitter.com/SrichidAcademy"><i class="lni lni-twitter-original"></i></a></li>
                            <li><a href="https://www.linkedin.com/in/srichid-academy-60a0761a5/"><i class="lni lni-linkedin-original"></i></a></li>
                            <li><a href="https://www.instagram.com/_srichid_academy/"><i class="lni lni-instagram-filled"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Header Bottom -->
    <div class="header-bottom">
        <div class="container">
            <div class="row">
                <div class="col-lg-2 col-md-2 col-12">
                    <!-- Logo -->
                    <div class="logo">
                        <a href=""><img src="assets/img/prmia.png" alt="Srichid Logo"></a>
                    </div>
                    <!-- End Logo -->
                    <div class="mobile-nav"></div>
                </div>
                <div class="col-lg-10 col-md-10 col-12">
                    <!-- Main Menu -->
                    <div class="main-menu">
                        <nav class="navigation ">
                            <ul class="nav menu">
                                <li class="active"><a href="" (click)="gohome()">Home</a></li>
                                <li><a [routerLink]="['/webinar-registration']">Webinar</a></li>

                                <li *ngIf="all && !isLogged"><a href="#about-us">About Us</a></li>
                                <li *ngIf="all && !isLogged"><a href="#features">Features</a></li>
                                <!-- <li><a href="#pricing">Pricing </a></li> -->
                                <li *ngIf="all && !isLogged"><a href="#faq">FAQ </a></li>
                                <!-- <li><a href="#blog">Blog</a></li> -->
                                <li *ngIf="all && !isLogged"><a href="#contact-us">Contact Us</a></li>
                                <div class="dropdown">
                                    <br>
                                    <button class="btn btn-secondary dropdown-toggle" *ngIf="isLogged" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Training
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <li *ngIf="isLogged && admin"><a class="dropdown-item" [routerLink]="['/event']">Training Programs</a></li>
                                        <li *ngIf="isLogged && admin"> <a class="dropdown-item" [routerLink]="['/events']">Training Programs Preview</a></li>
                                        <li *ngIf="isLogged && !admin"> <a class="dropdown-item" [routerLink]="['/events']">Training Programs Preview</a></li>
                                      

                                    </div>
                                </div>
                                &nbsp;&nbsp;
                                <!-- <div class="dropdown">
                                    <br>
                                    <button class="btn btn-secondary dropdown-toggle" *ngIf="isLogged" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Invite
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                       
                                        <li *ngIf="isLogged && admin"> <a class="dropdown-item" [routerLink]="['/invite']">Invite to Event</a></li>
                                       
                                        <li *ngIf="isLogged && !admin"> <a class="dropdown-item" [routerLink]="['/invite']">Invite to Event</a></li>

                                    </div>
                                </div>
                                &nbsp;&nbsp; -->
                                <div class="dropdown">
                                    <br>
                                    <button class="btn btn-secondary dropdown-toggle"  *ngIf="isLogged" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      Blog
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <li *ngIf="isLogged && admin"><a class="dropdown-item" [routerLink]="['/blog']">Blog Programs</a></li>
                                        <li *ngIf="isLogged && admin"> <a class="dropdown-item" [routerLink]="['/allblog']">Blog Programs Preview</a></li>
                                        <li *ngIf="isLogged && !admin"> <a class="dropdown-item" [routerLink]="['/allblog']">Blog Programs Preview</a></li>

                                    </div>
                                </div>
                                &nbsp;&nbsp;
                                <div class="dropdown" >
                                    <br>
                                    <button class="btn btn-secondary dropdown-toggle" *ngIf="isLogged && admin" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Webinar
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <li *ngIf="isLogged && admin"><a class="dropdown-item" [routerLink]="['/webinar']">Webinar Programs</a></li>
                                        <li *ngIf="isLogged && admin"> <a class="dropdown-item" [routerLink]="['/allwebinar']">Webinar Programs Preview</a></li>
                                        <li *ngIf="isLogged && admin"><a class="dropdown-item" [routerLink]="['/count']">Count</a></li>
                                    </div>
                                </div>


                                <li *ngIf="isLogged && !admin"><a style="cursor: pointer;" href="http://prmia.org/Public/PRM/Company_Pages/SRICHID_PRM_Enrollment.aspx" target="_blank">PRMIA Membership</a></li>

                             

                                <li *ngIf="isLogged && !admin"><a (click)="gojob()">Go <span style="    text-transform: none;">to</span> Jobs-zone</a></li>

                            </ul>
                        </nav>
                    </div>
                    <!--/ End Main Menu -->
                    <a href="register" *ngIf="!isLogged" class="button">Get Started</a>
                    <a href="" *ngIf="isLogged" (click)="logout()" class="button">Sign Out</a>

                </div>
            </div>
        </div>
    </div>
    <!--/ End Header Bottom -->
</header>
<router-outlet></router-outlet>