<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>

<main>
    <div class="content">
        <div class="d-flex h-100 align-items-center justify-content-center">
            <div class="container">
                <div class="row">
                    <div class="col-md-5 col-sm-6 ml-auto mr-auto">
                        <div class="card">
                            <div class="card-body">
                                <section class="container register-form">
                                    <div class="form">
                                        <div class="row text-center justify-content-center">
                                            <div class="glowBox">
                                                <h4 class="title">Register to Webinar</h4>
                                            </div>
                                            <div class="desc text-center">
                                                <p><img class="site__img" src="/assets/img/prmia.png" style="width: 180px;"> </p>
                                                </div>
                                        </div>
                                    </div>
                                    <div class="form-content">
                                        <div class="container">
                                            <div class="row justify-content-center mt--300"></div>
                                            <form #JobPost="ngForm" (ngSubmit)="OnSubmitJobPost(JobPost)" ngNativeValidate>
                                                <div class="row">
                                                    <input class="form-control" #Name type="text" [ngModel] id="Name" name="Name" style="cursor: auto" placeholder="Name" required /><span class="underline"></span>

                                                    <input class="form-control" #Email type="Email" [ngModel] id="Email" name="Email" placeholder="Email" style="cursor: auto" required /><span class="underline"></span>

                                                    <input class="form-control" #Phone type="number" [ngModel] id="Phone" name="Phone" placeholder="Phone" style="cursor: auto" required /><span class="underline"></span>

                                                    <input class="form-control" #Organization type="text" [ngModel] id="Organization" name="Organization" placeholder="Organization" style="cursor: auto" required /><span class="underline"></span>

                                                    <input class="form-control" #Designation type="text" [ngModel] id="Designation" name="Designation" placeholder="Designation" style="cursor: auto" required /><span class="underline"></span>


                                                    <a href="https://drive.google.com/file/d/1iA_ZtOdJLWPrXDpJbhP3yue0NOF7mNY1/view?usp=drive_link" target="_blank" >Tap here to view the invitation of the event </a>

                                                    <a href="https://drive.google.com/file/d/1bgJqm-r6lKSq9mPX1XPBeVPjJ3-yLv-O/view?usp=drive_link" target="_blank" >Tap here to view the brochure of the event </a>
                                                    <div class="col-md-12">&nbsp;</div>

                                                    <div class="col-md-12" style="text-align: center">
                                                        <button type="submit" class="btn btn-primary btn-link btn-wd btn-lg">
                                                        Register
                                                        </button><br><br><br>PRMIA-SRICHID-RiskHub<br><br>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>