<app-header></app-header>


<div id="container">


    <div id="hero-text">
      <h1 style="    font-size: 20px;"> One's Top Solution</h1><br>
      <h1>PRM<sup style="    font-size: calc(1rem + 0.9vw);">TM</sup> Designation</h1>
  </div>
  <ul id="abc">
    <li><i class="fa fa-check" aria-hidden="true"></i>&nbsp; High paying jobs / Global career</li>
    <li><i class="fa fa-check" aria-hidden="true"></i>&nbsp;Job placement through SRICHID Jobs-Zone
    </li>
    <li><i class="fa fa-check" aria-hidden="true"></i>&nbsp;For India, we have a special discount of 20% in PRM Fees USD 1150<br>compared to USD 1430 ( Graduate Students price is USD 828)</li>
  <li><i class="fa fa-check" aria-hidden="true"></i>&nbsp;Flexible Online exams, quarterly testing windows</li>
  <li><i class="fa fa-check" aria-hidden="true"></i>&nbsp;PRM is recognized by government of india and leading banks and corporates</li>
  </ul>
  <div class="button" style="color: white;    margin-top: 12%;
  margin-left: 46%;">
    <a href="register" *ngIf="!islogin" class="btn btn-secondary">Get Started</a>
    <a href="http://prmia.org/Public/PRM/Company_Pages/SRICHID_PRM_Enrollment.aspx" target="_blank" *ngIf="islogin" class="btn btn-secondary">PRMIA Membership </a>

</div>
    <div id="overlay"></div>
   
   
  </div>


<!-- 
<section class="hero-header primary-header slider-header" id="home">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-12">
                <div class="hero-header-content">
                    <p>Plan your life</p>
                    <h1> <b>Professional Risk Manager</b> Designation</h1>
                    <p>
                   
                    </p>
                    <div class="button">
                        <a href="register" *ngIf="!islogin" class="btn btn-secondary">Get Started</a>
                        <a href="http://prmia.org/Public/PRM/Company_Pages/SRICHID_PRM_Enrollment.aspx" target="_blank" *ngIf="islogin" class="btn btn-secondary">PRMIA Membership </a>

                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-12">
                <div class="hero-header-image">
                    <img src="assets/img/prmia1.svg" alt="#">
                </div>
            </div>
        </div>
    </div>
</section> -->


<!-- new section-->
<!-- <section class="services-area section" id="features">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-12">
                <div class="info-text">
                    <h4 class="short-title">PRMIA Advantages</h4>
                    <h5 class="main-title"> PRM Benefits through SRICHID:</h5>
                    <div class="icon-box mt-5 mt-lg-0" data-aos="fade-up" data-aos-delay="100">
                 
                        <h6>(i)                 High paying jobs / Global career</h6>          <br/>
              
                        <h6>(ii)                Job placement through SRICHID Jobs-Zone</h6>          <br/>
              
                        <h6>(iii)               For India, we have a special discount of 20% in PRM Fees USD 1150 compared to USD 1430  ( Graduate Students price is USD 828)</h6>          <br/>
              
                        <h6>(iv)                Flexible Online exams, quarterly testing windows</h6>          <br/>
                    </div>
                    <div class="button">
                        <a href="https://prmia.informz.net/PRMIA/pages/2019_PRM_Guidebook" *ngIf="!islogin" class="btn btn-secondary" style="color: white;">PRM Guidebook</a>

                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-12">
                <div class="info-media right" style="text-align: center;">
                    <img src="assets/img/job.svg" style="width: 80%;" alt="Plan">
                </div>
            </div>
          
        </div>
        <br><br>

    </div>
</section> -->


<section id="about-us" class="work section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-title">
                    <h2>Read about our master</h2>
                    <p>Srichid Technologies and Srichid Academy is headed by Professor Satchidananda S Sogala, Ph.D., a Risk Guru and a technology leader in banking, academia and the IT industry. An Alumnus of the University of Illinois at Urbana Champaign,
                        he has rich experience of applying risk management and IT innovatively in diverse spheres as the Senior Central Banker; as the Professor and CEBIT Research , Director at IIIT; as the Head of Risk and Research in IBM and lately
                        as Founder of Srichid Technologies and Srichid Academy.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-12">
                <div class="single-work">
                    <div class="serial">
                        <span><i class="lni lni-home"></i></span>
                    </div>
                    <h3 style="font-weight: 600;">Steps</h3>
                    <p>Enroll <br> Take Classes <br> Get Placed </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <div class="single-work">
                    <div class="serial">
                        <span><i class="lni lni-graduation"></i></span>
                    </div>
                    <h3 style="font-weight: 600;">Expertise</h3>
                    <p>Banking <br> Risk Management <br> Training</p>

                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <div class="single-work last">
                    <div class="serial">
                        <span><i class="lni lni-handshake"></i></span>
                    </div>
                    <h3 style="font-weight: 600;">Major Partners</h3>
                    <p> PRMIA <br> <br><br></p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <div class="single-work last">
                    <div class="serial">
                        <span><i class="lni lni-download"></i></span>
                    </div>
                    <h3 style="font-weight: 600;">Organise</h3>
                    <p>Events <br>Placement Oppurtunities <br>Seminars</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /End Work Area -->

<!-- Start Services Area -->
<section class="services-area section" id="features">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-12">
                <div class="info-media right" style="text-align: center;">
                    <img src="assets/img/plan.svg" style="width: 80%;" alt="Plan">
                </div>
            </div>
            <div class="col-lg-5 col-12">
                <div class="info-text">
                    <p class="short-title">Our Features</p>
                    <h2 class="main-title">Plan and Manage</h2>
                    <ul class="des">
                        <li>Membership in PRMIA</li>
                        <li>Training</li>
                        <li>Exams</li>
                        <li>Certification</li>
                        <li>Placement Oppurtunities</li>
                    </ul>

                    <div class="button">
                        <a href="register" *ngIf="!islogin" class="btn" style="color: white;">Get Started </a>
                        <a (click)="gotomembership()" *ngIf="islogin" class="btn" style="color: white;cursor: pointer;">PRMIA Membership </a>
                        <!-- <a (click)="gotoenroll()" *ngIf="islogin" class="btn" style="color: white;">PRM Designation </a> -->

                    </div>
                </div>
            </div>
        </div>
        <br><br>

    </div>
</section>
<!-- End Services Area -->











<!-- Start Counter Section-->
<!-- <section class="product-counter-section">
    <div class="product-counter-wrap">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-12">
                    <div class="counter-content-wrap">
                        <i class="lni lni-rocket"></i>
                        <h6 class="counter-title"><strong>Trusted by 4000+</strong></h6>
                        <p class="counter-text">High performing team worldwide</p>
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <ul class="counter-list list-inline text-right">
                        <li>
                            <span class="number count">1000</span>
                            <span class="title">Happy Clients</span>
                        </li>
                        <li>
                            <span class="number count">4400</span>
                            <span class="title">Wining Award</span>
                        </li>
                        <li>
                            <span class="number count">5000</span>
                            <span class="title">Team Member</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- /End Counter Section-->

<!-- Pricing Table -->
<!-- <section id="pricing" class="pricing-table section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-title">
                    <h2>Our <span>Pricing</span></h2>
                    <p>Business consulting excepteur sint occaecat cupidatat consulting non proident, sunt in culpa qui officia deserunt laborum market. </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-12">
              
                <div class="single-table">
                    <div class="table-head">
                        <h4 class="title">Basic <span>for small business</span></h4>
                        <div class="price">
                            <p class="amount"><span class="curency">$</span>29<span class="duration">/mo</span></p>
                        </div>
                    </div>
                    <ul class="table-list">
                        <li>3 Users</li>
                        <li>Unlimited Projects</li>
                        <li>Download Prototypes</li>
                    </ul>
                    <div class="button">
                        <a class="btn" href="#">Get Started</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <div class="single-table">
                    <div class="table-head">
                        <h4 class="title">Standard <span>for companies</span></h4>
                        <div class="price">
                            <p class="amount"><span class="curency">$</span>59<span class="duration">/mo</span></p>
                        </div>
                    </div>
                    <ul class="table-list">
                        <li>3 Users</li>
                        <li>Unlimited Projects</li>
                        <li>Download Prototypes</li>
                    </ul>
                    <div class="button">
                        <a class="btn" href="#">Get Started</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <div class="single-table">
                    <p class="popular">Popular</p>
                    <div class="table-head">
                        <h4 class="title">Professonal <span>for small business</span></h4>
                        <div class="price">
                            <p class="amount"><span class="curency">$</span>79<span class="duration">/mo</span></p>
                        </div>
                    </div>
                    <ul class="table-list">
                        <li>3 Users</li>
                        <li>Unlimited Projects</li>
                        <li>Download Prototypes</li>
                    </ul>
                    <div class="button">
                        <a class="btn" href="#">Get Started</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <div class="single-table">
                    <div class="table-head">
                        <h4 class="title">Most Popular <span>for small business</span></h4>
                        <div class="price">
                            <p class="amount"><span class="curency">$</span>99<span class="duration">/mo</span></p>
                        </div>
                    </div>
                    <ul class="table-list">
                        <li>3 Users</li>
                        <li>Unlimited Projects</li>
                        <li>Download Prototypes</li>
                    </ul>
                    <div class="button">
                        <a class="btn" href="#">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!--/ End Pricing Table -->

<!-- Start Testimonials Section -->
<!-- <section id="testimonials" class="section testimonials">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-title">
                    <h2>Peoples Talk</h2>
                    <p>With great record on training and placements. We have had a great bunch of interns trained from us.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="testimonial-slider owl-carousel">
                    <div class="single-testimonial">
                        <p>" It was a very experience as i was being exposed to new concepts like Angular, Machine Learning etc... and now I am able to apply these concepts in the real world problem." </p>
                        <div class="bottom">
                            <img src="assets/img/testi1.jpg" alt="#">
                            <h4 class="name">Bharath B R<span>Student</span></h4>
                        </div>
                    </div>

                    <div class="single-testimonial">
                        <p>"The internship at Srichid was very helpful for me, the people here are very helpful and technically expert. They taught me Angular and Machine Learning." </p>
                        <div class="bottom">
                            <img src="assets/img/testi2.jpg" alt="#">
                            <h4 class="name">Prasad Patel<span>Student</span></h4>
                        </div>
                    </div>

                    <div class="single-testimonial">
                        <p>" I just brought it and i love it. Easily the best tool for mockups i have encountered ever and that's why this is the best for me." </p>
                        <div class="bottom">
                            <img src="assets/img/testi3.jpg" alt="#">
                            <h4 class="name">kerio chal<span>CEO - Apptrend</span></h4>
                        </div>
                    </div>

                    <div class="single-testimonial">
                        <p>" I just brought it and i love it. Easily the best tool for mockups i have encountered ever and that's why this is the best for me." </p>
                        <div class="bottom">
                            <img src="assets/img/testi1.jpg" alt="#">
                            <h4 class="name">kerio chal<span>CEO - Apptrend</span></h4>
                        </div>
                    </div>

                    <div class="single-testimonial">
                        <p>" I just brought it and i love it. Easily the best tool for mockups i have encountered ever and that's why this is the best for me." </p>
                        <div class="bottom">
                            <img src="assets/img/testi3.jpg" alt="#">
                            <h4 class="name">kerio chal<span>CEO - Apptrend</span></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- /End Testimonials Section -->

<!-- Start Team -->
<!-- <section id="team" class="team section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-title">
                    <h2>Our awesome Team</h2>
                    <p>Business consulting excepteur sint occaecat cupidatat consulting non proident, sunt in culpa qui officia deserunt laborum market. </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-12">
                <div class="single-team">
                    <div class="image">
                        <img src="assets/img/team/team1.jpg" alt="#">
                    </div>
                    <div class="info-head">
                        <div class="info-box">
                            <h4 class="name"><a href="#">Dahlia Moore</a></h4>
                            <span class="designation">Senior Manager</span>
                        </div>
                        <div class="social-links">
                            <ul class="social">
                                <li><a href="#"><i class="fa fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fa fa-behance"></i></a></li>
                                <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <div class="single-team">
                    <div class="image">
                        <img src="assets/img/team/team2.jpg" alt="#">
                    </div>
                    <div class="info-head">
                        <div class="info-box">
                            <h4 class="name"><a href="#">Jhone digo</a></h4>
                            <span class="designation">Markeitng</span>
                        </div>
                        <div class="social-links">
                            <ul class="social">
                                <li><a href="#"><i class="fa fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fa fa-behance"></i></a></li>
                                <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <div class="single-team">
                    <div class="image">
                        <img src="assets/img/team/team3.jpg" alt="#">
                    </div>
                    <div class="info-head">
                        <div class="info-box">
                            <h4 class="name"><a href="#">Zara tingo</a></h4>
                            <span class="designation">Web Developer</span>
                        </div>
                        <div class="social-links">
                            <ul class="social">
                                <li><a href="#"><i class="fa fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fa fa-behance"></i></a></li>
                                <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <div class="single-team">
                    <div class="image">
                        <img src="assets/img/team/team4.jpg" alt="#">
                    </div>
                    <div class="info-head">
                        <div class="info-box">
                            <h4 class="name"><a href="#">David Zone</a></h4>
                            <span class="designation">SEO Expert</span>
                        </div>
                        <div class="social-links">
                            <ul class="social">
                                <li><a href="#"><i class="fa fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fa fa-behance"></i></a></li>
                                <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!--/ End Team Area -->





<!--Frequently asked questions start-->
<section id="faq" class="faq-section section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-title">
                    <h2>Frequently asked questions</h2>
                    <p></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div id="accordion" class="mt-4 faq-container">
                    <div class="simple-card">
                        <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                                <button class=" btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                             <span class="arrow-container"></span> How to enroll for membership ?
                             </button>
                            </h5>
                        </div>
                        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                            <div class="card-body">
                                <ul>
                                    <li>First step is to get registered to Riskhub</li>
                                    <li>Then fill your details in <a href="http://prmia.org/Public/PRM/Company_Pages/SRICHID_PRM_Enrollment.aspx" style="text-decoration: underline;cursor: pointer;" target="_blank">membership registration page</a> </li>
                                    <li>Make the payment</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="simple-card">
                        <div class="card-header" id="headingTwo">
                            <h5 class="mb-0">
                                <button class=" btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                             <span class="arrow-container"></span> How much does it cost to get membership of PRMIA?
                             </button>
                            </h5>
                        </div>
                        <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div class="card-body ">
                                It is very affordable and the cost is $100 only. That's not it you also get access to:
                                <li>24 Thought Leadership webinars per year</li>
                                <li>Member discount on PRMIA partner training courses</li>
                                <li>Special member pricing on certificate program</li>
                                <li>Networking/Events access &amp; much more.</li>

                                <!-- <a href="#" class=" btn">Go to documentation</a> -->
                            </div>
                        </div>
                    </div>
                    <div class="simple-card">
                        <div class="card-header" id="headingThree">
                            <h5 class="mb-0">
                                <button class=" btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                             <span class="arrow-container"></span> Do I get access to Webinars posted on website after taking membership?
                             </button>
                            </h5>
                        </div>
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                            <div class="card-body">
                                Yes! you get access to 24 Leadership webinar per year.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Frequently asked questions end-->

<!-- Start Blog Area -->
<!-- <section class="blog section" id="blog">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-title">
                    <h2>Read Our Blog</h2>
                    <p>Business consulting excepteur sint occaecat cupidatat consulting non proident, sunt in culpa qui officia deserunt laborum market. </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-12">
                <div class="single-news">
                    <div class="news-head">
                        <img src="assets/img/blog1.jpg" alt="#">
                    </div>
                    <div class="news-body">
                        <div class="news-content">
                            <div class="date">22 Aug, 2020</div>
                            <h2><a href="blog-single-sidebar.html">We have annnocuced our new product.</a></h2>
                            <p class="text">Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt sed do incididunt sed.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
                <div class="single-news">
                    <div class="news-head">
                        <img src="assets/img/blog2.jpg" alt="#">
                    </div>
                    <div class="news-body">
                        <div class="news-content">
                            <div class="date">15 Jul, 2020</div>
                            <h2><a href="blog-single-sidebar.html">Top five way for learning web development.</a></h2>
                            <p class="text">Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt sed do incididunt sed.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
                <div class="single-news">
                    <div class="news-head">
                        <img src="assets/img/blog3.jpg" alt="#">
                    </div>
                    <div class="news-body">
                        <div class="news-content">
                            <div class="date">05 Jan, 2020</div>
                            <h2><a href="blog-single-sidebar.html">We provide highly business soliutions.</a></h2>
                            <p class="text">Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt sed do incididunt sed.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Blog Area -->
<!-- <---clients--> 
<div class="wrap">
    <div class="section-title"><h2 class="main-title">SOME GLOBAL PARTNERS
    </h2></div>
    <ul class="clients">
      <li><a href=""><img src="assets/img/Picture1.png" alt="Google"></a></li>
      <li><a href=""><img src="assets/img/Picture2.jpg" alt="Airbnb"></a></li>
      <li><a href=""><img src="assets/img/Picture3.jpg"alt="Apple"></a></li>
      <li><a href=""><img src="assets/img/Picture4.jpg" alt="Microsoft"></a></li>

      <li><a href=""><img src="assets/img/Picture5.jpg" alt="Google"></a></li>
      <li><a href=""><img src="assets/img/Picture6.png" alt="Airbnb"></a></li>
      <li><a href=""><img src="assets/img/Picture7.jpg"alt="Apple"></a></li>
      <li><a href=""><img src="assets/img/Picture8.jpg" alt="Microsoft"></a></li>

      <li><a href=""><img src="assets/img/Picture9.png"alt="Apple"></a></li>
      <li><a href=""><img src="assets/img/Picture10.jpg" alt="Microsoft"></a></li>
      <li><a href=""><img src="assets/img/Picture11.jpg"alt="Apple"></a></li>
      <li><a href=""><img src="assets/img/Picture12.jpg" alt="Microsoft"></a></li>
    </ul>
  </div>
<!-- Start Contact Area -->
<section id="contact-us" class="contact-us section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-title">
                    <h2>Contact Us</h2>
                    <!-- <p>We value our members and trainee. </p> -->
                </div>
            </div>
        </div>
        <div class="contact-head">
            <div class="row">
                <div class="col-lg-5 col-12">
                    <div class="single-head overlay">
                        <div class="single-info">
                            <i class="fa fa-phone"></i>
                            <h4 class="title">Call us Now:</h4>
                            <ul>
                                <li><a href="tel:+91-80-26852070">+91-80-26852070</a></li>
                                <li><a href="tel:+91-80-26852070">+91-80-29753113</a></li>
                            </ul>
                        </div>
                        <div class="single-info">
                            <i class="fa fa-envelope-open"></i>
                            <h4 class="title">Email:</h4>
                            <ul>
                                <li><a href="mailto:prmia.riskhub.srichidacademy@gmail.com">prmia.riskhub.srichidacademy@gmail.com</a></li>
                                <!-- <li><a href="mailto:prmia-riskhub.srichidacademy">prmia-riskhub.srichidacademy</a></li> -->
                            </ul>
                        </div>
                        <div class="single-info">
                            <i class="fa fa-location-arrow"></i>
                            <h4 class="title">Our Address:</h4>
                            <ul>
                                <li>#563, RBI Layout, VII Phase JP Nagar, Bangalore, Karnataka, India-560078.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-12">
                    <div class="form-main">
                        <div class="title">
                            <h4>Get in touch</h4>
                            <h3>Write us a message</h3>
                        </div>
                        <form class="form" method="post" action="mail/mail.php">
                            <div class="row">
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Your Name<span>*</span></label>
                                        <input name="name" [(ngModel)]="name" type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Your Subject<span>*</span></label>
                                        <input name="subject" [(ngModel)]="subject" type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Your Email<span>*</span></label>
                                        <input name="email" [(ngModel)]="email" type="email" placeholder="">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Your Phone<span>*</span></label>
                                        <input name="phone" [(ngModel)]="phone" type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group message">
                                        <label>Your message<span>*</span></label>
                                        <textarea name="message" [(ngModel)]="message" placeholder=""></textarea>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group button">
                                        <button type="submit" (click)="submit()" class="btn ">Send Message</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--/ End Contact Area -->

<!--Get Started start-->
<section id="get-started" class="get-started section">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-12">
                <div class="inner-content">
                    <p class="small-title">Plan your Growth
                    </p>
                    <h1 class="main-title">Get <b>started</b> now</h1>
                    <p class="des">You are just one step away from accomplishing highest ranks and getting yourself trained under the recognised experts.</p>
                    <a href="register" *ngIf="!islogin" class="btn">Get Started</a>
                    <a (click)="gotomembership()" style="cursor: pointer;" *ngIf="islogin" class="btn">PRMIA Membership </a> &nbsp;&nbsp;
                    <!-- <a (click)="gotoenroll()" *ngIf="islogin" class="btn">PRM Designation </a> -->


                </div>
            </div>
        </div>
    </div>
</section>
<!--Get Started end-->

<!-- Start Newsletter Area -->
<!-- <section class="newsletter section">

    <div class="container">
        <div class="row ">
            <div class="col-lg-6  col-12">
                <div class="subscribe-text ">
                    <h6>Sign up for newsletter</h6>
                    <p class="">Cu qui soleat partiendo urbanitas. Eum aperiri indoctum eu,<br> homero alterum.</p>
                </div>
            </div>
            <div class="col-lg-6  col-12">
                <div class="subscribe-form ">
                    <form action="mail/mail.php" method="get" target="_blank" class="newsletter-inner">
                        <input name="EMAIL" placeholder="Your email address" class="common-input" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Your email address'" required="" type="email">
                        <button class="btn">Save Me</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section> -->
<app-footer></app-footer>