<app-header></app-header>
<br><br>
<h1 style="width: 100vw;text-align: center;">Blogs </h1>
<br>
<ul class="cards">  

    <li class="cards__item" *ngFor="let events of Events">
        <div class="card" style="width: 100%;">
            <div class="card__image card__image--default" style="background-image: url({{events.eventPoster}});">
                <!-- <img src="https://i.stack.imgur.com/s96ST.jpg" alt=""> -->
            </div>
            <div class="card__content">
                <div class="card__title" style="font-weight: bold;">{{events.eventName}} - {{events.eventDate}}</div>
                <br>
                <p class="card__text">{{events.eventDescription}}</p>
                <br>
                <!-- <button class="btn btn--block card__btn" (click)="Modalpopupplay(events.eventDocuments,play)">Play</button> -->
            </div>
        </div>
    </li>


</ul>
<br><br>

<ng-template #play let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Please wait until video loads</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
    </div>
    <div class="modal-body">
        <video style="width: 100%;height: 100%;" controls (click)="toggleVideo()" preload #videoPlayer>
            <source src="{{url}}"   type="video/mp4" />
            Browser not supported
        </video>
    </div>
    <br />
    <div class="modal-footer">

        <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')" style="color: black; cursor: pointer;">
                Close
            </button>
    </div>
    <br />
</ng-template>
<app-footer></app-footer>