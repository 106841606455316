<app-header></app-header>
<div class="col-md-12 row">
    &nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>

<!-- <div class="col-md-12" id="divstyle"> 
    <label id="labeldiv">To</label>
    <div id="aboveTagInp">
        <tag-input ng-disabled="all" id="ShareTagInp" [ngModel]="selectedFriend" name="test" [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)" [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
            [separatorKeyCodes]="[13,188,186,32]">
            13 - enter, 188 - comma ,186 - semicolon 
            <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'email'" [identifyBy]="'uid'" [autocompleteItems]="frndslist">
                <ng-template let-item="item" let-index="index">
                    {{ item.email }}
                </ng-template>
            </tag-input-dropdown>
        </tag-input>
    </div>

 </div> <br />  -->