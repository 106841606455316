import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EventService {
private readonly globalurl="https://prmiaapi.srichid.academy/api";
//private readonly globalurl="http://localhost:8541/api";
  constructor(private http:HttpClient) { }
  GetEvents()
  {
  return  this.http.get(this.globalurl+"/Account/GetallEvent");
  }

AddEvent(data:any)
{
  return  this.http.post(this.globalurl+"/Account/AddEvent",data);
}

EditEvent(data:any)
{
  return this.http.post(this.globalurl+"/Account/EditEvent",data);
}

DeleteEvent(id)
{
  return this.http.get(this.globalurl+"/Account/GetDeleteEvent/"+id);
}
GetBlogs()
  {
  return  this.http.get(this.globalurl+"/Account/GetallBlog");
  }

AddBlog(data:any)
{
  return  this.http.post(this.globalurl+"/Account/AddBlog",data);
}

EditBlog(data:any)
{
  return this.http.post(this.globalurl+"/Account/EditBlog",data);
}

DeleteBlog(id)
{
  return this.http.get(this.globalurl+"/Account/GetDeleteBlog/"+id);
}

save(state: any) {
  return this.http.post(this.globalurl + '/Account/save', state);
}
saveWebinarevent2(state: any) {
  return this.http.post(this.globalurl + '/Account/saveevent2', state);
}

GetWebinars()
{
return  this.http.get(this.globalurl+"/Account/GetallWebinar");
}
AddWebinar(data:any)
{
  return  this.http.post(this.globalurl+"/Account/AddWebinar",data);
}

EditWebinar(data:any)
{
  return this.http.post(this.globalurl+"/Account/EditWebinar",data);
}

DeleteWebinar(id)
{
  return this.http.get(this.globalurl+"/Account/GetDeleteWebinar/"+id);
}
GetLists()
{
return  this.http.get(this.globalurl+"/Account/Getalllist");
}

}
